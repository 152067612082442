import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import License from './License';
import ButtonComponent from './ButtonComponent';

// Animation variants for motion elements
const containerVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 120, // Slower animation with reduced stiffness
      damping: 26, // Smoother slowdown
    },
  },
};

// Animation variants for license items
const licenseVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: (index: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: index * 0.2,
      type: 'spring',
      stiffness: 100,
      damping: 20,
    },
  }),
};

// License data as pills
const licenses = [
  { title: 'Asbestos', number: '#02019', iconColor: 'text-primaryAlt' },
  { title: 'Lead', number: '#00803A, #00806E', iconColor: 'text-secondary' },
  {
    title: 'General Contractor',
    number: '#13VH12917600',
    iconColor: 'text-primary',
  },
];

const MobileHero: React.FC = () => {
  const navigate = useNavigate();

  return (
    // Container with relative positioning for layering video, overlay, and content
    <div className="relative h-[145vh] xs:h-[110vh] xsm:h-[115vh] sm:h-screen sm:h-screen w-full rounded-md">
      {/* Background video */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover rounded-md"
        src={`/imgs/work_promo_${Math.floor(Math.random() * 8) + 1}.webm`} // Randomized video source from 1 to 9
        autoPlay
        loop
        muted
      />
      {/* Dark/gray opaque overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-50 rounded-md"></div>
      {/* License section as a portion of the top of the hero */}
      <div className="absolute top-0 left-0 w-full bg-gradient-to-b from-gray-800 via-gray-800/70 to-transparent py-4 px-4 z-20 rounded-b-md">
        <div className="flex flex-wrap gap-4 justify-start">
          {licenses.map((lic, index) => (
            <motion.div
              key={index}
              className="text-white flex items-center"
              custom={index}
              initial="hidden"
              animate="visible"
              variants={licenseVariants}
            >
              <div className="flex items-center p-2">
                <div className="block">
                  <svg
                    className={`w-4 h-4 sm:w-6 sm:h-6 ${lic.iconColor} mr-2 sm:mr-4`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 2a8 8 0 100 16 8 8 0 000-16zm1 11H9v2h2v-2zm0-8H9v6h2V5z" />
                  </svg>
                </div>
                <div>
                  <p className="text-sm md:text-lg font-semibold text-white">
                    {lic.title}
                  </p>
                  <p className="bg-red-600 text-white inline-block px-1 rounded-md shadow-md relative overflow-hidden">
                    License:
                    <span className="absolute inset-0 bg-gradient-to-r from-transparent via-white/40 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300"></span>
                  </p>
                  <p className="text-gray-300">{lic.number}</p>
                </div>
              </div>
            </motion.div>
          ))}
          {/* logo */}

          {/* <img
            className="max-w-[50%] scale-50"
            src="/imgs/Abated Main Logo 800x600.png"
            alt=""
          /> */}
        </div>
      </div>
      {/* Content overlay */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full px-4 text-start">
        {/* License pills with updated background and text styles */}
        {/* <div className="flex flex-wrap gap-2 mb-4">
          {licenses.map((lic, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-full px-3 py-1 flex items-center"
            >
              <License
                title={lic.title}
                number={lic.number}
                iconColor={lic.iconColor}
              />
            </div>
          ))}
        </div> */}
        {/* Animated logo for XL screens with white font */}
        <motion.div
          className="hidden xl:flex flex-col gap-4 text-white font-semibold px-6 md:text-xl"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <img src="/imgs/Abated Main Logo ALT.png" alt="" />
        </motion.div>
        {/* Animated hero text with updated white font */}
        <motion.div
          className="flex flex-col gap-4 text-white font-semibold px-6 md:text- bg-gray-800/70 p-4 rounded-md"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <p>
            Here at Abated, we specialize in{' '}
            <span className="bg-gray-200/40 rounded-lg px-2 text-white">
              Asbestos Abatement
            </span>
            ,{' '}
            <span className="bg-gray-200/40 rounded-lg px-2 text-white">
              Lead Abatement
            </span>
            ,{' '}
            <span className="bg-gray-200/40 rounded-lg px-2 text-white">
              Lead Inspections
            </span>
            ,{' '}
            <span className="bg-gray-200/40 rounded-lg px-2 text-white">
              Asbestos Inspections
            </span>
            ,{' '}
            <span className="bg-gray-200/40 rounded-lg px-2 text-white">
              Demolition
            </span>{' '}
            and{' '}
            <span className="bg-gray-200/40 rounded-lg px-2 text-white">
              General Contracting
            </span>
            . We are a <span className="text-white">licensed</span> and insured
            company that is dedicated to providing our customers with the best
            service possible.
          </p>
          <p>
            We have completed many projects throughout the New Jersey area. Our
            team is made up of highly trained professionals who are committed to
            providing quality workmanship at competitive prices. If you need any
            help with your project, please contact us today!
          </p>
        </motion.div>
        {/* Animated call-to-action button */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="w-full flex justify-center items-center">
            <div className="">
              <ButtonComponent
                label={'Lets get that quote'}
                onClick={() => {
                  // Navigate to the services page
                  navigate('/services');
                }}
              />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default MobileHero;
