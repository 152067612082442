import { Suspense } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { Route, Routes } from 'react-router-dom';

// pages
import Home from './pages/Home';
import Footer from './components/Footer';
import WildRoute from './pages/WildRoute';
import Careers from './pages/Careers';
import Services from './pages/Services';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Loading from './components/Loading';
import WIP from './components/WIP';
import Contact from './pages/Contact';
import TermsConditions from './pages/TermsConditions';
import LeadBooking from './pages/LeadBooking';
// import Particles, { initParticlesEngine } from '@tsparticles/react';
// import {
//   type Container,
//   type ISourceOptions,
//   MoveDirection,
//   OutMode,
// } from '@tsparticles/engine';
// import { loadSlim } from '@tsparticles/slim';

function App() {
  // const [init, setInit] = useState(false);

  // // this should be run only once per application lifetime
  // useEffect(() => {
  //   initParticlesEngine(async (engine) => {
  //     // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
  //     // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  //     // starting from v2 you can add only the features you need reducing the bundle size
  //     //await loadAll(engine);
  //     //await loadFull(engine);
  //     await loadSlim(engine);
  //     //await loadBasic(engine);
  //   }).then(() => {
  //     setInit(true);
  //   });
  // }, []);

  // const particlesLoaded = async (container?: Container): Promise<void> => {
  //   console.log(container);
  // };

  // const options: ISourceOptions = useMemo(
  //   () => ({
  //     autoPlay: true,
  //     background: {
  //       color: {
  //         value: '#ffffff',
  //       },
  //       image: '',
  //       position: '',
  //       repeat: '',
  //       size: '',
  //       opacity: 1,
  //     },
  //     backgroundMask: {
  //       composite: 'destination-out',
  //       cover: {
  //         color: {
  //           value: '#fff',
  //         },
  //         opacity: 1,
  //       },
  //       enable: false,
  //     },
  //     clear: true,
  //     defaultThemes: {},
  //     delay: 0,
  //     fullScreen: {
  //       enable: true,
  //       zIndex: -1,
  //     },
  //     detectRetina: true,
  //     duration: 0,
  //     fpsLimit: 60,
  //     interactivity: {
  //       detectsOn: 'window',
  //       events: {
  //         onClick: {
  //           enable: false,
  //           mode: [],
  //         },
  //         onDiv: {
  //           selectors: [],
  //           enable: false,
  //           mode: [],
  //           type: 'circle',
  //         },
  //         onHover: {
  //           enable: true,
  //           mode: 'trail',
  //           parallax: {
  //             enable: false,
  //             force: 2,
  //             smooth: 10,
  //           },
  //         },
  //         resize: {
  //           delay: 0.5,
  //           enable: true,
  //         },
  //       },
  //       modes: {
  //         trail: {
  //           delay: 0.005,
  //           pauseOnStop: true,
  //           quantity: 5,
  //           particles: {
  //             color: {
  //               value: '#ff0000',
  //               animation: {
  //                 enable: true,
  //                 speed: 400,
  //                 sync: true,
  //               },
  //             },
  //             collisions: {
  //               enable: false,
  //             },
  //             links: {
  //               enable: false,
  //             },
  //             move: {
  //               outModes: {
  //                 default: 'destroy',
  //               },
  //               speed: 2,
  //             },
  //             size: {
  //               value: {
  //                 min: 1,
  //                 max: 5,
  //               },
  //               animation: {
  //                 enable: true,
  //                 speed: 5,
  //                 sync: true,
  //                 startValue: 'min',
  //                 destroy: 'max',
  //               },
  //             },
  //           },
  //         },
  //         attract: {
  //           distance: 200,
  //           duration: 0.4,
  //           easing: 'ease-out-quad',
  //           factor: 1,
  //           maxSpeed: 50,
  //           speed: 1,
  //         },
  //         bounce: {
  //           distance: 200,
  //         },
  //         bubble: {
  //           distance: 200,
  //           duration: 0.4,
  //           mix: false,
  //           divs: {
  //             distance: 200,
  //             duration: 0.4,
  //             mix: false,
  //             selectors: [],
  //           },
  //         },
  //         connect: {
  //           distance: 80,
  //           links: {
  //             opacity: 0.5,
  //           },
  //           radius: 60,
  //         },
  //         grab: {
  //           distance: 100,
  //           links: {
  //             blink: false,
  //             consent: false,
  //             opacity: 1,
  //           },
  //         },
  //         push: {
  //           default: true,
  //           groups: [],
  //           quantity: 4,
  //         },
  //         remove: {
  //           quantity: 2,
  //         },
  //         repulse: {
  //           distance: 200,
  //           duration: 0.4,
  //           factor: 100,
  //           speed: 1,
  //           maxSpeed: 50,
  //           easing: 'ease-out-quad',
  //           divs: {
  //             distance: 200,
  //             duration: 0.4,
  //             factor: 100,
  //             speed: 1,
  //             maxSpeed: 50,
  //             easing: 'ease-out-quad',
  //             selectors: [],
  //           },
  //         },
  //         slow: {
  //           factor: 3,
  //           radius: 200,
  //         },
  //         light: {
  //           area: {
  //             gradient: {
  //               start: {
  //                 value: '#ffffff',
  //               },
  //               stop: {
  //                 value: '#000000',
  //               },
  //             },
  //             radius: 1000,
  //           },
  //           shadow: {
  //             color: {
  //               value: '#000000',
  //             },
  //             length: 2000,
  //           },
  //         },
  //       },
  //     },
  //     manualParticles: [],
  //     particles: {
  //       bounce: {
  //         horizontal: {
  //           value: 1,
  //         },
  //         vertical: {
  //           value: 1,
  //         },
  //       },
  //       collisions: {
  //         absorb: {
  //           speed: 2,
  //         },
  //         bounce: {
  //           horizontal: {
  //             value: 1,
  //           },
  //           vertical: {
  //             value: 1,
  //           },
  //         },
  //         enable: false,
  //         maxSpeed: 50,
  //         mode: 'bounce',
  //         overlap: {
  //           enable: true,
  //           retries: 0,
  //         },
  //       },
  //       color: {
  //         value: '#cccccc',
  //         animation: {
  //           h: {
  //             count: 0,
  //             enable: true,
  //             speed: 50,
  //             decay: 0,
  //             delay: 0,
  //             sync: false,
  //             offset: 0,
  //           },
  //           s: {
  //             count: 0,
  //             enable: false,
  //             speed: 1,
  //             decay: 0,
  //             delay: 0,
  //             sync: true,
  //             offset: 0,
  //           },
  //           l: {
  //             count: 0,
  //             enable: false,
  //             speed: 1,
  //             decay: 0,
  //             delay: 0,
  //             sync: true,
  //             offset: 0,
  //           },
  //         },
  //       },
  //       effect: {
  //         close: true,
  //         fill: true,
  //         options: {},
  //         type: [],
  //       },
  //       groups: {},
  //       move: {
  //         angle: {
  //           offset: 0,
  //           value: 90,
  //         },
  //         attract: {
  //           distance: 200,
  //           enable: false,
  //           rotate: {
  //             x: 3000,
  //             y: 3000,
  //           },
  //         },
  //         center: {
  //           x: 50,
  //           y: 50,
  //           mode: 'percent',
  //           radius: 0,
  //         },
  //         decay: 0,
  //         distance: {},
  //         direction: 'none',
  //         drift: 0,
  //         enable: true,
  //         gravity: {
  //           acceleration: 9.81,
  //           enable: false,
  //           inverse: false,
  //           maxSpeed: 50,
  //         },
  //         path: {
  //           clamp: true,
  //           delay: {
  //             value: 0,
  //           },
  //           enable: false,
  //           options: {},
  //         },
  //         outModes: {
  //           default: 'out',
  //           bottom: 'out',
  //           left: 'out',
  //           right: 'out',
  //           top: 'out',
  //         },
  //         random: false,
  //         size: false,
  //         speed: 2,
  //         spin: {
  //           acceleration: 0,
  //           enable: false,
  //         },
  //         straight: false,
  //         trail: {
  //           enable: false,
  //           length: 10,
  //           fill: {},
  //         },
  //         vibrate: false,
  //         warp: false,
  //       },
  //       number: {
  //         density: {
  //           enable: true,
  //           width: 1920,
  //           height: 1080,
  //         },
  //         limit: {
  //           mode: 'delete',
  //           value: 0,
  //         },
  //         value: 100,
  //       },
  //       opacity: {
  //         value: 1,
  //         animation: {
  //           count: 0,
  //           enable: true,
  //           speed: 0.5,
  //           decay: 0,
  //           delay: 0,
  //           sync: false,
  //           mode: 'auto',
  //           startValue: 'random',
  //           destroy: 'none',
  //         },
  //       },
  //       reduceDuplicates: false,
  //       shadow: {
  //         blur: 0,
  //         color: {
  //           value: '#000',
  //         },
  //         enable: false,
  //         offset: {
  //           x: 0,
  //           y: 0,
  //         },
  //       },
  //       shape: {
  //         close: true,
  //         fill: true,
  //         options: {},
  //         type: 'circle',
  //       },
  //       size: {
  //         value: {
  //           min: 1,
  //           max: 3,
  //         },
  //         animation: {
  //           count: 0,
  //           enable: true,
  //           speed: 3,
  //           decay: 0,
  //           delay: 0,
  //           sync: false,
  //           mode: 'auto',
  //           startValue: 'random',
  //           destroy: 'none',
  //         },
  //       },
  //       stroke: {
  //         width: 0,
  //       },
  //       zIndex: {
  //         value: 0,
  //         opacityRate: 1,
  //         sizeRate: 1,
  //         velocityRate: 1,
  //       },
  //       destroy: {
  //         bounds: {},
  //         mode: 'none',
  //         split: {
  //           count: 1,
  //           factor: {
  //             value: 3,
  //           },
  //           rate: {
  //             value: {
  //               min: 4,
  //               max: 9,
  //             },
  //           },
  //           sizeOffset: true,
  //           particles: {},
  //         },
  //       },
  //       roll: {
  //         darken: {
  //           enable: false,
  //           value: 0,
  //         },
  //         enable: false,
  //         enlighten: {
  //           enable: false,
  //           value: 0,
  //         },
  //         mode: 'vertical',
  //         speed: 25,
  //       },
  //       tilt: {
  //         value: 0,
  //         animation: {
  //           enable: false,
  //           speed: 0,
  //           decay: 0,
  //           sync: false,
  //         },
  //         direction: 'clockwise',
  //         enable: false,
  //       },
  //       twinkle: {
  //         lines: {
  //           enable: false,
  //           frequency: 0.05,
  //           opacity: 1,
  //         },
  //         particles: {
  //           enable: false,
  //           frequency: 0.05,
  //           opacity: 1,
  //         },
  //       },
  //       wobble: {
  //         distance: 5,
  //         enable: false,
  //         speed: {
  //           angle: 50,
  //           move: 10,
  //         },
  //       },
  //       life: {
  //         count: 0,
  //         delay: {
  //           value: 0,
  //           sync: false,
  //         },
  //         duration: {
  //           value: 0,
  //           sync: false,
  //         },
  //       },
  //       rotate: {
  //         value: 0,
  //         animation: {
  //           enable: false,
  //           speed: 0,
  //           decay: 0,
  //           sync: false,
  //         },
  //         direction: 'clockwise',
  //         path: false,
  //       },
  //       orbit: {
  //         animation: {
  //           count: 0,
  //           enable: false,
  //           speed: 1,
  //           decay: 0,
  //           delay: 0,
  //           sync: false,
  //         },
  //         enable: false,
  //         opacity: 1,
  //         rotation: {
  //           value: 45,
  //         },
  //         width: 1,
  //       },
  //       links: {
  //         blink: false,
  //         color: {
  //           value: 'random',
  //         },
  //         consent: false,
  //         distance: 100,
  //         enable: true,
  //         frequency: 1,
  //         opacity: 1,
  //         shadow: {
  //           blur: 5,
  //           color: {
  //             value: '#000',
  //           },
  //           enable: false,
  //         },
  //         triangles: {
  //           enable: false,
  //           frequency: 1,
  //         },
  //         width: 1,
  //         warp: false,
  //       },
  //       repulse: {
  //         value: 0,
  //         enabled: false,
  //         distance: 1,
  //         duration: 1,
  //         factor: 1,
  //         speed: 1,
  //       },
  //     },
  //     pauseOnBlur: true,
  //     pauseOnOutsideViewport: true,
  //     responsive: [],
  //     smooth: false,
  //     style: {},
  //     themes: [],
  //     zLayers: 100,
  //     emitters: [],
  //     motion: {
  //       disable: false,
  //       reduce: {
  //         factor: 4,
  //         value: true,
  //       },
  //     },
  //   }),
  //   []
  // );

  return (
    <>
      {true && (
        <>
          {/* <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
          /> */}

          <div className="flex flex-col w-full justify-center items-center bg-background overflow-auto scrollbar-hide text-primaryText">
            <div className="flex flex-col w-full relative z-10">
              {/* header */}
              <Navbar />

              {/* SPA Container */}
              <div className="flex justify-center items-center py-24">
                <div className="flex flex-col md:max-w-[80%] lg:max-w-[90%] xl:max-w-[90%]">
                  <Suspense fallback={<Loading />}>
                    <Routes>
                      <Route path="*" element={<WildRoute />}></Route>
                      <Route path="/" element={<Home />}></Route>
                      <Route path="/about" element={<WIP />}></Route>
                      <Route path="/projects" element={<WIP />}></Route>
                      <Route path="/blog" element={<WIP />}></Route>
                      <Route path="/careers" element={<Careers />}></Route>
                      <Route path="/contact" element={<Contact />}></Route>
                      <Route path="/history" element={<WIP />}></Route>
                      <Route path="/services" element={<Services />}></Route>
                      <Route
                        path="/booking/lead"
                        element={<LeadBooking />}
                      ></Route>
                      <Route
                        path="/privacypolicy"
                        element={<PrivacyPolicy />}
                      ></Route>
                      <Route
                        path="/termsandconditions"
                        element={<TermsConditions />}
                      ></Route>
                    </Routes>
                  </Suspense>
                </div>
              </div>

              {/* footer */}
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default App;
