import React from 'react';
import { useNavigate } from 'react-router-dom';

const inspectionTypes = [
  { label: 'Lead Inspection', route: '/booking/lead' },
  // {
  //   label: 'Book Asbestos Inspection',
  //   route: '/asbestos-inspection',
  // },
  // { label: 'Book Mold Inspection', route: '/mold-inspection' },
  // Add more inspection types as needed
];

const UndergroundShelf = () => {
  const navigate = useNavigate();

  const handleButtonClick = (route: string) => {
    navigate(route);
  };

  return (
    <div className="bg-gray-900 p-4 rounded-t-lg shadow-md relative overflow-hidden">
      <div className="absolute inset-0 shadow-inner"></div>
      <div className="flex justify-start items-center h-16 overflow-hidden">
        <div className="flex animate-marquee">
          {inspectionTypes.map((type, index) => (
            <button
              key={index}
              className="bg-gray-700 text-white text-sm px-3 py-3 m-1 rounded hover:bg-gray-600 transform transition-transform duration-200 active:scale-95"
              onClick={() => handleButtonClick(type.route)}
            >
              {type.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UndergroundShelf;
